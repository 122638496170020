<template>
    <router-view/>
</template>
<script>
export default {
    created () {
        window.addEventListener('scroll', this.handleScroll);
    },
    methods: {
        handleScroll (event) {
            const navContainEl = document.querySelector('.nav-container');
            if (navContainEl) {
                console.log(navContainEl);
                if (window.scrollY > 50 && !navContainEl.classList.contains('nav-lg-white')) {
                    navContainEl.classList.add('nav-lg-white');
                } else if (window.scrollY < 50 && navContainEl.classList.contains('nav-lg-white')) {
                    navContainEl.classList.remove('nav-lg-white');
                }
            }
        }
    }
};
</script>
