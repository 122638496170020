import FormTextArea from 'lego-framework/src/forms/FormTextArea';

export default {
    name: 'form-textarea-lg',
    extends: FormTextArea,
    props: {
        inputClasses: { type: [String, Array], default: 'h-lg-75vh h-50vh of-a textarea-resize-none b-1 b-dark mt-2' },
        textareaClasses: { type: [String, Array], default: 'h-100p thin-scrollbar sb-color-gray' },
        labelClasses: { type: [String, Array], default: 'text-4 font-weight-600 text-fs-3' }
    }
};
