import guards from './guards';

const Layout = () => import(/* webpackChunkName: "layout" */'../layouts/BlankLayout.vue');
const Home = () => import(/* webpackChunkName: "home" */'../views/home/HomePage');
const About = () => import(/* webpackChunkName: "home" */'../views/About');
const CreateBot = () => import(/* webpackChunkName: "bots" */'../views/bots/CreateBot');
const ListBot = () => import(/* webpackChunkName: "bots" */'../views/bots/ListBot');
const Emails = () => import(/* webpackChunkName: "bots" */'../views/bots/Emails');
const EmailsPromptEditor = () => import(/* webpackChunkName: "bots" */'../views/bots/EmailsPromptEditor.vue');
const ChatBots = () => import(/* webpackChunkName: "bots" */'../views/chatbots/ChatBot.vue');
const ProjectCreation = () => import(/* webpackChunkName: "bots" */'../views/projects/ProjectCreation.vue');
const ProjectList = () => import(/* webpackChunkName: "bots" */'../views/projects/ProjectList.vue');
const ProjectDetails = () => import(/* webpackChunkName: "bots" */'../views/projects/ProjectDetails.vue');

const PromptEditor = () => import(/* webpackChunkName: "bots" */'../views/editor/PromptEditor.vue');
const PersonaEditor = () => import(/* webpackChunkName: "bots" */'../views/editor/PersonaEditor.vue');
const TonalityEditor = () => import(/* webpackChunkName: "bots" */'../views/editor/TonalityEditor.vue');

export default {
    path: '/',
    name: 'DashboardLayout',
    redirect: '/app/',
    component: Layout,
    beforeEnter: guards.loggedInGuard,
    children: [
        { path: '/', name: 'Home', component: Home },
        { path: '/about/', name: 'About', component: About },
        { path: '/create-bot/', name: 'CreateBot', component: CreateBot },
        { path: '/list-bot/', name: 'ListBot', component: ListBot },
        { path: '/emails/', name: 'Emails', component: Emails },
        { path: '/emails-editor/', name: 'EmailsPromptEditor', component: EmailsPromptEditor },
        { path: '/prompt-bot/', name: 'ListBot', component: ListBot },
        { path: '/chat-bots/', name: 'EditChatBot', component: ChatBots },

        // Master Data
        { path: '/prompt-editor/', name: 'PromptEditor', component: PromptEditor },
        { path: '/persona-editor/', name: 'PersonaEditor', component: PersonaEditor },
        { path: '/tonality-editor/', name: 'TonalityEditor', component: TonalityEditor },

        // Projects
        { path: '/project-creation/', name: 'ProjectCreation', component: ProjectCreation },
        { path: '/project/:id/', name: 'ProjectDetails', component: ProjectDetails },
        { path: '/projects/', name: 'ProjectList', component: ProjectList }
    ]
};
